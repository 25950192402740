// import all curb-dark styles, but under the following html tag selector so as not to collide with non curb-dark pages
html.curb-dark {
  @import '../../../node_modules/shared/styles/themes/curb-dark/index.less';
  @import '../../../node_modules/shared/styles/atoms/loading-spinner.less';
  @import '../../../node_modules/shared/styles/atoms/modal.less';
  @import 'forms.less';
  @import 'config.less';
  @import 'modals/index.less';
  @import 'components/index.less';

  body {
    overflow: auto;
  }

  .virtual-load-control-page {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }

  .app-header {
    height: auto;
    border: none;
    margin: 0;
  }

  .app-main {
    padding: 1rem 0;    
  }

  button,
  .button {
    &,
    &[type=submit] {
      height: auto;
      line-height: 1;
      padding: 0.5rem;
      width: auto !important;
      margin-right: initial;
    }
  }

  .react-datepicker {
    button.react-datepicker__navigation {
        position: absolute;
        padding: 0;
        top: auto;
        height: 10px !important;
        width: 10px !important;      
    }
  } 

  .status-message .error {
    color: inherit;
  }

}