.priorities {
  .info {
    font-style: italic;
  }
  .platform-nav {
    padding: 0 1rem;
    position: relative;
    .platforms-list {
      padding: 0.5rem 0;
      display: flex;
      .platform-item {
        padding: 0 1rem;
        flex: 1;
        width: 0;
        flex-wrap: wrap;

        .link {
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .status-indicator,
        .loading-spinner {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-right: 0.4rem;          
        }

        .status-indicator {
          border-radius: 50%;
          padding: 0.125rem;
          background: @color-light-3;
        }
        &.active .status-indicator,
        .active .status-indicator {
          background: @color-status-good;
        }

        &.loading {
          .link {
            cursor: wait;
            opacity: 0.5;  
          }
        }

        &.has-error {
          .link {
            cursor: default;
            &:hover {
              text-decoration: none
            }  
          }
        }

        .status-in-progress {
          font-size: 0.8em;
          font-style: italic;
        }

        .status-message {
          margin: 0.5rem;
          overflow: hidden;
          .stack {
            line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &.loading {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: wait;
      }
      .integrations-list {
        opacity: 0.5;
      }
    }
  }

  .priorities-list {
    padding: 2rem 0;
  }

  .priorities-item {
    .section-title {
      // margin-top: -3.5rem;
      display: flex;
      position: relative;
      h3 {
        justify-self: center;
      }
      button {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .order-controls {
      position: absolute;
      top: 0;
      left: 0;
      button {
        min-width: initial;
        border-radius: 0;
        box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5);
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        & + button {
          margin-left: 1px;
        }
      }      
    }

    .close {
      background: @color-status-alert;
      min-width: initial;
      position: absolute;
      top: 0;
      right: 0;
      svg {
        transition: transform 0.2s ease-in-out;
      }
      &:hover:not([disabled]) {
        opacity: 1;
        svg {
          transform: rotate(90deg);
        }
      }
    }

    & + .priorities-item {
      padding-top: 0;
      margin-top: 2rem;
    }

    .form-row {
      padding: 1rem 2rem;
      .curb-form-element {
        flex: 1;
      }
    }

    .curb-select {
      label {
        font-size: 0.75rem;
      }
    }
  }
}

.relay-configs-list {
  padding: 2rem;
  .relay-config-item {
    display: flex;
    align-items: center;
    .label {
      margin-right: 1rem;
    }
    & > .curb-form-element {
      flex: 1;
      label {
        font-size: 0.8rem;
      }
    }
  }
}

.virtual-load-control-submit {
  .curb-submit {
    margin: 1rem 0;
    .button,
    button {
      margin-left: 1rem;
    }
  }
}