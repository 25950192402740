.holidays-modal {
  .modal-wrapper {
    display: flex;
    & > .inset {
      display: flex;
      flex-direction: column;
      justify-content: stretch;      
      & > .inner {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        max-height: 100%;
        .curb-form {
          flex-direction: column;
          justify-content: stretch;
          max-height: 100%;  
        }
      }
    }
  }
  .exceptions-list {
    padding: 0 3rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    overflow: auto;
    position: relative;
    &:before,
    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      height: 1rem;
    }
    &:before {
      top: 0;
      background: linear-gradient(0deg, rgba(52,52,52,1) 0%, rgba(52,52,52,0) 100%);
    }
    &:after {
      bottom: 0;
      background: linear-gradient(180deg, rgba(52,52,52,1) 0%, rgba(52,52,52,0) 100%);
    }
  }
  .exceptions-item {
    display: flex;
    align-items: center;
    label {
      display: flex;
    }
    & + .exceptions-item {
      // margin-top: 1rem;
    }

    .curb-datepicker {
      margin-bottom: 0;
    }    
  }

  label,
  .label {
    font-size: 1rem;
  }

  .holiday-checkbox-slider {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    margin-left: auto;
    margin-bottom: 0 !important;
    position: relative;
  
    .message {
      position: absolute; 
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 0.8em;
      color: @color-status-good;
      margin-bottom: 0.2rem;
    }
    &:not(.checked) {
      .toggler {
        background: @color-status-warning;
      }
      .message {
        color: @color-status-warning;
      }
    }
    .toggler {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      .icon {
        font-size: 0.6rem;
      }
    }
  }



  .curb-form-element + .curb-form-element {
    margin-top: 0;
  }
  
  .add-holiday {
    margin-top: 1rem;
  }

}

