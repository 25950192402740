.virtual-load-control-config-form {
  .monitor-interval {
    display: flex;
    align-items: center;
    .select-monitor_interval_mins {
      margin: 0 0.5rem !important;
      select {
        width: 4rem;
      }
    }
  }
  .launch-holiday-modal {
    padding: 1rem 0;
  }
  .unsaved-changes-message {
    position: absolute;
    bottom: 100%;
    right: 0;
    max-width: 10rem;
    text-align: left;
    display: flex;
    svg {
      margin-right: 0.2rem;
    }
  }
}