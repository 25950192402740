.enforcement-window-list {
  padding: 2rem 0;
  .enforcement-window {
    padding-top: 0;
    & + .enforcement-window {
      margin-top: 3rem;
    }
    .section-title {
      position: relative;
    }
    .close {
      font-size: 1.3rem;
      position: absolute;
      padding: .5rem;
      background: @color-action-irreversible;
      opacity: 1;
      border-radius: 0.2rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;    
      cursor: pointer;
      svg {
        transition: transform 0.2s ease-in-out;
      }
      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }    
    }
  
    .time-checkbox-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 0;
      .time-checkbox-item {
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
        padding: 0.5rem 0.5rem;
        .curb-styled-checkbox label {
          width: 100%;
          font-size: 1rem;
        }
        
        .date-styled-checkbox {
          margin-bottom: 0;
        }
      }
      &.months-list {
        .time-checkbox-item {
          flex-basis: 100%/6;
        }
      }
    }
  
    .time-ranges-list {
      padding: 1rem;
      .time-range {
        display: flex;
        align-items: center;
        .curb-form-element.curb-datepicker {
          margin: 0;
          flex: 1;
          .react-datepicker-wrapper {
            width: 100%;
          }
          label {
            padding: 0 0 3px;
            font-size: 0.8rem
          }
        }
        & > label {
          font-size: 1rem;
          font-weight: bold;
          padding: 0 0.5rem;
        }
    
        & + .time-range {
          margin-top: 1rem;
        }

      }

      .status-message {
        margin: 1rem 0 0;      
      }
    
      .add-time-range {
        margin-top: 1rem;
      }
    }
  
  }
}
