@import 'enforcement-windows.less';
@import 'priorities.less';

.threshold-group {
  h3 {
    padding-bottom: 0.5rem;
  }
  .form-row {
    align-items: stretch;
    select {
      height: 2.25rem;
    }
  }
  .status-message {
    font-weight: normal;
  }
}