.curb-form {
  color: inherit;
  flex: initial;

  .curb-form-element {
    padding-top: 0;
  }

  input,
  textarea,
  select {
    font-size: inherit;    
  }

  section.narrow {
    width: 300px;
  }

  label,
  .label {
    font-size: 1rem;
  }

}

.virtual-load-control-search-form {
  .search-input-wrap {
    label {
      font-style: italic;
    }
    .icon {
      transform: none;
      top: auto;
      bottom: 0.6rem;
      &[data-icon=times-circle] {
        bottom: 0.4rem;
      }
    }
  
    .curb-input {
      box-shadow: none;
      input {
        box-shadow: 0 0 3px rgba(0,0,0,.5);
      }
    }
  }

  .location {
    .info {
      font-style: italic;
    }
    .address-full {
      padding-left: 2rem;
      padding-top: 0.5rem;
      font-weight: bold;
      font-family: monospace;
    }
  }

}

.virtual-load-control-config-form.curb-form {
  .curb-submit {
    justify-content: space-between;
  }  
}